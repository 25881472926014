<template>
    <k-layout class="tw-bg-white">
        <template #default>
            <k-container class="tw-py-16">
                <template #default>
                    <h1 class="tw-heading-1">{{ $t('event.title') }}</h1>

                    <div class="tw-grid md:tw-grid-cols-2 tw-mt-16 tw-gap-x-16 tw-gap-y-12 tw-grid-cols-1">
                        <div>
                            <p class="tw-pb-6 tw-heading-4 tw-border-b-2 tw-border-gray-200">{{ $t('event.subtitles.online') }}</p>
                            <div class="tw-mt-4">
                                <ul class="tw-list-none tw-p-0 tw-divide-y tw-divide-gray-200">
                                    <li v-for="event in upcomingOnlineEvents" :key="event.id" class="tw-py-6">
                                        <event-preview :event="event" />
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <p class="tw-pb-6 tw-heading-4 tw-border-b-2 tw-border-gray-200">{{ $t('event.subtitles.slovakia_czech') }}</p>
                            <div class="tw-mt-4">
                                <ul class="tw-list-none tw-p-0 tw-divide-y tw-divide-gray-200">
                                    <li v-for="event in upcomingLocalEvents" :key="event.id" class="tw-py-6">
                                        <event-preview :event="event" show-location />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </k-container>
        </template>
    </k-layout>
</template>

<script>
import EventPreview from "./../../events/components/event-preview";

export default {
    components: {
        EventPreview
    },

    created() {
        this.$store.dispatch("EVENTS/getAllEvents");
    },

    computed: {
        upcomingOnlineEvents() {
            const items = this.$store.getters["EVENTS/upcomingOnlineEvents"];
            return items;
        },

        upcomingLocalEvents() {
            const items = this.$store.getters["EVENTS/upcomingLocalEvents"];
            return items;
        }
    }
};
</script>
